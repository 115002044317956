import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

var exports = {};
var _require = _preact,
    h = _require.h;

exports = function (props) {
  return [h("span", {
    className: "uppy-ProviderBrowser-user",
    key: "username"
  }, props.username), h("button", {
    type: "button",
    onClick: props.logout,
    className: "uppy-u-reset uppy-ProviderBrowser-userLogout",
    key: "logout"
  }, props.i18n("logOut"))];
};

export default exports;