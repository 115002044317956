import * as _classnames2 from "classnames";

var _classnames = "default" in _classnames2 ? _classnames2.default : _classnames2;

import _Filter from "./Filter";
import _ItemList from "./ItemList";
import _FooterActions from "./FooterActions";
import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this || _global, arguments);
}

var classNames = _classnames;
var Filter = _Filter;
var ItemList = _ItemList;
var FooterActions = _FooterActions;
var _require = _preact,
    h = _require.h;

var Browser = function Browser(props) {
  var currentSelection = props.currentSelection,
      folders = props.folders,
      files = props.files,
      uppyFiles = props.uppyFiles,
      filterItems = props.filterItems,
      filterInput = props.filterInput;
  var filteredFolders = folders;
  var filteredFiles = files;

  if (filterInput !== "") {
    filteredFolders = filterItems(folders);
    filteredFiles = filterItems(files);
  }

  var selected = currentSelection.length;
  return h("div", {
    className: classNames("uppy-ProviderBrowser", "uppy-ProviderBrowser-viewType--" + props.viewType)
  }, h("div", {
    className: "uppy-ProviderBrowser-header"
  }, h("div", {
    className: classNames("uppy-ProviderBrowser-headerBar", !props.showBreadcrumbs && "uppy-ProviderBrowser-headerBar--simple")
  }, props.headerComponent)), props.showFilter && h(Filter, props), h(ItemList, {
    columns: [{
      name: "Name",
      key: "title"
    }],
    folders: filteredFolders,
    files: filteredFiles,
    sortByTitle: props.sortByTitle,
    sortByDate: props.sortByDate,
    isChecked: props.isChecked,
    handleFolderClick: props.getNextFolder,
    toggleCheckbox: props.toggleCheckbox,
    handleScroll: props.handleScroll,
    title: props.title,
    showTitles: props.showTitles,
    i18n: props.i18n,
    viewType: props.viewType,
    validateRestrictions: props.validateRestrictions,
    uppyFiles: uppyFiles,
    currentSelection: currentSelection
  }), selected > 0 && h(FooterActions, _extends({
    selected: selected
  }, props)));
};

exports = Browser;
export default exports;