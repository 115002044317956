import _ProviderView from "./ProviderView";
import _SearchProviderView from "./SearchProviderView";
var exports = {};
var ProviderViews = _ProviderView;
var SearchProviderViews = _SearchProviderView;
exports = {
  ProviderViews: ProviderViews,
  SearchProviderViews: SearchProviderViews
};
export default exports;
const _ProviderViews = exports.ProviderViews,
      _SearchProviderViews = exports.SearchProviderViews;
export { _ProviderViews as ProviderViews, _SearchProviderViews as SearchProviderViews };