import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

var exports = {};
var _require = _preact,
    h = _require.h;

exports = function (props) {
  return h("button", {
    type: "button",
    onClick: props.triggerSearchInput,
    className: "uppy-u-reset uppy-ProviderBrowser-userLogout"
  }, props.i18n("backToSearch"));
};

export default exports;