import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

import * as _classnames2 from "classnames";

var _classnames = "default" in _classnames2 ? _classnames2.default : _classnames2;

import _ItemIcon from "./components/ItemIcon";
import _GridLi from "./components/GridLi";
import _ListLi from "./components/ListLi";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this || _global, arguments);
}

var _require = _preact,
    h = _require.h;
var classNames = _classnames;
var ItemIcon = _ItemIcon;
var GridLi = _GridLi;
var ListLi = _ListLi;

exports = function (props) {
  var itemIconString = props.getItemIcon();
  var className = classNames("uppy-ProviderBrowserItem", {
    "uppy-ProviderBrowserItem--selected": props.isChecked
  }, {
    "uppy-ProviderBrowserItem--disabled": props.isDisabled
  }, {
    "uppy-ProviderBrowserItem--noPreview": itemIconString === "video"
  });
  var itemIconEl = h(ItemIcon, {
    itemIconString: itemIconString
  });

  switch (props.viewType) {
    case "grid":
      return h(GridLi, _extends({}, props, {
        className: className,
        itemIconEl: itemIconEl
      }));

    case "list":
      return h(ListLi, _extends({}, props, {
        className: className,
        itemIconEl: itemIconEl
      }));

    default:
      throw new Error("There is no such type " + props.viewType);
  }
};

export default exports;