import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

var exports = {};
var _require = _preact,
    h = _require.h;

exports = function (props) {
  return h("div", {
    className: "uppy-ProviderBrowser-footer"
  }, h("button", {
    className: "uppy-u-reset uppy-c-btn uppy-c-btn-primary",
    onClick: props.done
  }, props.i18n("selectX", {
    smart_count: props.selected
  })), h("button", {
    className: "uppy-u-reset uppy-c-btn uppy-c-btn-link",
    onClick: props.cancel
  }, props.i18n("cancel")));
};

export default exports;