import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

var exports = {};
var _require = _preact,
    h = _require.h;

exports = function (props) {
  return h("div", {
    className: "uppy-Provider-loading"
  }, h("span", null, props.i18n("loading")));
};

export default exports;